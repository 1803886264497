import React, { useState, useEffect, useRef } from 'react';

import { Container, Row, Col, Table, Form, Button } from 'react-bootstrap';

import './App.css';
import Corredor from './Corredor';

import jsPDF from 'jspdf';
import 'jspdf-autotable';


const corredores = {
	
    "1": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredora1",
        "CAT": "ABSOLUTA",
        "ID": "1",
        "Sexo": "FEMENINO"
    },
    "2": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredora2",
        "CAT": "ABSOLUTA",
        "ID": "2",
        "Sexo": "FEMENINO"
    },
    "3": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor3",
        "CAT": "ABSOLUTA",
        "ID": "3",
        "Sexo": "MASCULINO"
    },
    "4": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor4",
        "CAT": "ABSOLUTA",
        "ID": "4",
        "Sexo": "MASCULINO"
    },
    "5": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor5",
        "CAT": "ABSOLUTA",
        "ID": "5",
        "Sexo": "MASCULINO"
    },
    "6": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor6",
        "CAT": "ABSOLUTA",
        "ID": "6",
        "Sexo": "MASCULINO"
    },
    "7": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor7",
        "CAT": "ABSOLUTA",
        "ID": "7",
        "Sexo": "MASCULINO"
    },
    "8": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor8",
        "CAT": "ABSOLUTA",
        "ID": "8",
        "Sexo": "MASCULINO"
    },
    "9": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor9",
        "CAT": "ABSOLUTA",
        "ID": "9",
        "Sexo": "MASCULINO"
    },
    "10": {
        "Apellidos": "Apellido1 Apellido2",
        "Nombre": "Corredor10",
        "CAT": "ABSOLUTA",
        "ID": "10",
        "Sexo": "MASCULINO"
    },
	"11": {
        "Apellidos": "COTERA GONZALO",
        "Nombre": "DIEGO",
        "CAT": "ABSOLUTA",
        "ID": "11",
        "Sexo": "MASCULINO"
    }
    
}


function App() {
  const [dorsalInput, setDorsalInput] = useState('');
  const [timeInput, setTimeInput] = useState('');
  const [results, setResults] = useState([]);
  const [position, setPosition] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [editingEntry, setEditingEntry] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [totalPausedTime, setTotalPausedTime] = useState(0);
  const [lastPauseTime, setLastPauseTime] = useState(null);
  const [intervals, setIntervals] = useState([]);
  const timerRef = useRef(null);

  useEffect(() => {
    const storedStartTime = localStorage.getItem('startTime');
    const storedResults = localStorage.getItem('results');
    const storedPosition = localStorage.getItem('position');
    const storedIntervals = localStorage.getItem('intervals');
    const storedTotalPausedTime = localStorage.getItem('totalPausedTime');

    if (storedStartTime) {
      const currentTime = Date.now();
      const elapsed = currentTime - parseInt(storedStartTime, 10) - (storedTotalPausedTime ? parseInt(storedTotalPausedTime, 10) : 0);
      setStartTime(parseInt(storedStartTime, 10));
      setElapsedTime(elapsed);
      setIsRunning(true);
    }

    if (storedResults) {
      setResults(JSON.parse(storedResults));
    }

    if (storedPosition) {
      setPosition(parseInt(storedPosition, 10));
    }

    if (storedIntervals) {
      setIntervals(JSON.parse(storedIntervals));
    }

    if (storedTotalPausedTime) {
      setTotalPausedTime(parseInt(storedTotalPausedTime, 10));
    }
  }, []);

  useEffect(() => {
    if (isRunning) {
      timerRef.current = setInterval(() => {
        setElapsedTime(Date.now() - startTime - totalPausedTime);
      }, 1000);
    } else {
      clearInterval(timerRef.current);
    }
    return () => clearInterval(timerRef.current);
  }, [isRunning, startTime, totalPausedTime]);

  useEffect(() => {
    localStorage.setItem('results', JSON.stringify(results));
    localStorage.setItem('position', position.toString());
    localStorage.setItem('intervals', JSON.stringify(intervals));
    localStorage.setItem('totalPausedTime', totalPausedTime.toString());
  }, [results, position, intervals, totalPausedTime]);

  const handleDorsalChange = (e) => {
    setDorsalInput(e.target.value);
  };

  const handleTimeChange = (e) => {
    setTimeInput(e.target.value);
  };

  const handleDorsalSubmit = (e) => {
    e.preventDefault();
    if (editingEntry) {
      handleUpdate();
    } else {
      registerDorsalAndTime();
    }
  };

  const registerDorsalAndTime = () => {
    const dorsal = dorsalInput.trim().replace(/\s+/g, '');
    const currentTime = elapsedTime;
    const inputTime = timeInput ? parseTime(timeInput) : currentTime;

    if (corredores[dorsal]) {
      if (results.some(result => result.dorsal === dorsal)) {
        alert('El dorsal ya ha sido registrado.');
      } else {
        const newEntry = { position: position + 1, dorsal, corredor: corredores[dorsal], time: inputTime };
        const updatedResults = [...results, newEntry];
        setResults(sortByTime(updatedResults));
        setPosition(position + 1);
      }
      setDorsalInput('');
      setTimeInput('');
    } else {
      alert('Número de dorsal no encontrado.');
    }
  };

  const handleUpdate = () => {
    const updatedDorsal = dorsalInput.trim().replace(/\s+/g, '');
    const updatedTime = parseTime(timeInput);

    if (corredores[updatedDorsal]) {
      const updatedResults = results.map(result =>
        result.position === editingEntry.position ? { ...result, dorsal: updatedDorsal, corredor: corredores[updatedDorsal], time: updatedTime } : result
      );
      setResults(sortByTime(updatedResults));
      setEditingEntry(null);
      setDorsalInput('');
      setTimeInput('');
    } else {
      alert('Número de dorsal no encontrado.');
    }
  };

  const handleEdit = (position) => {
    const entry = results.find(result => result.position === position);
    setEditingEntry(entry);
    setDorsalInput(entry.dorsal);
    setTimeInput(formatTime(entry.time));
  };

  const handleDelete = (position) => {
    const updatedResults = results.filter(result => result.position !== position);
    setResults(sortByTime(updatedResults));
    setPosition(position => position - 1);
  };

  const parseTime = (timeStr) => {
    const [hrs, mins, secs] = timeStr.split(':').map(Number);
    return (hrs * 3600 + mins * 60 + secs) * 1000;
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hrs = Math.floor(totalSeconds / 3600);
    const mins = Math.floor((totalSeconds % 3600) / 60);
    const secs = totalSeconds % 60;
    return `${hrs.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const handleStart = () => {
    const now = Date.now();
    if (!isRunning) {
      if (!startTime) {
        setStartTime(now);
        localStorage.setItem('startTime', now.toString());
      } else {
        const newIntervals = [...intervals, { start: now }];
        setIntervals(newIntervals);
        localStorage.setItem('intervals', JSON.stringify(newIntervals));
        if (lastPauseTime) {
          const newTotalPausedTime = totalPausedTime + (now - lastPauseTime);
          setTotalPausedTime(newTotalPausedTime);
          localStorage.setItem('totalPausedTime', newTotalPausedTime.toString());
        }
      }
      setIsRunning(true);
    }
  };

  const handleStop = () => {
    if (isRunning) {
      const now = Date.now();
      const newIntervals = intervals.map(interval => {
        if (!interval.end) {
          return { ...interval, end: now };
        }
        return interval;
      });
      setIntervals(newIntervals);
      localStorage.setItem('intervals', JSON.stringify(newIntervals));
      setIsRunning(false);
      setLastPauseTime(now);
    }
  };

  const handleReset = () => {
    setIsRunning(false);
    setElapsedTime(0);
    setResults([]);
    setPosition(0);
    setStartTime(null);
    setIntervals([]);
    setTotalPausedTime(0);
    setLastPauseTime(null);
    localStorage.removeItem('startTime');
    localStorage.removeItem('results');
    localStorage.removeItem('position');
    localStorage.removeItem('intervals');
    localStorage.removeItem('totalPausedTime');
  };

  const sortByTime = (results) => {
    return results.sort((a, b) => a.time - b.time).map((result, index) => ({
      ...result,
      position: index + 1
    }));
  };

  const handleDownloadPDF = () => {
    const doc = new jsPDF();
    doc.text('Clasificaciones', 10, 10);
    const headers = ['Posición', 'Dorsal', 'Nombre', 'Apellidos', 'Tiempo'];
    const data = results.map(result => [
      result.position,
      result.dorsal,
      result.corredor.Nombre,
      result.corredor.Apellidos,
      formatTime(result.time)
    ]);
    doc.autoTable({
      head: [headers],
      body: data,
    });
    doc.save('clasificaciones.pdf');
  };

  return (
    <Container className="mt-5">
      <h1 className="text-center mb-4">
        <a href="#"><img src={`${process.env.PUBLIC_URL}/cronostera.svg`} alt="Logo de Cronostera" width="200" style={{ marginRight: '10px' }} />
        <span hidden>Cronostera</span></a>
      </h1>
      <Row className="mb-4">
        <Col className="text-center">
          <h2 className="display-4">{formatTime(elapsedTime)}</h2>
          <div className="d-flex justify-content-center mt-3">
            <Button variant="primary" className="me-2" onClick={handleStart}>Iniciar</Button>
            <Button variant="secondary" className="me-2" onClick={handleStop}>Parar</Button>
            <Button variant="danger" onClick={handleReset}>Reiniciar</Button>
          </div>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col>
          <Form onSubmit={handleDorsalSubmit} className="d-flex justify-content-center">
            <Form.Group className="me-2">
              <Form.Label>Número de Dorsal:</Form.Label>
              <Form.Control
                type="text"
                id="dorsal"
                value={dorsalInput}
                onChange={handleDorsalChange}
                autoFocus
              />
            </Form.Group>
            <Form.Group className="me-2">
              <Form.Label>Tiempo (hh:mm:ss):</Form.Label>
              <Form.Control
                type="text"
                id="time"
                value={timeInput}
                onChange={handleTimeChange}
              />
            </Form.Group>
            {editingEntry ? (
              <Button type="submit" variant="warning">Actualizar</Button>
            ) : (
              <Button type="submit" variant="success">Registrar</Button>
            )}
          </Form>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Posición</th>
                <th>Dorsal</th>
                <th>Nombre</th>
                <th>Apellidos</th>
                <th>Tiempo</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody id="results">
              {results.map((item) => (
                <Corredor
                  key={item.position}
                  item={item}
                  formatTime={formatTime}
                  onEdit={handleEdit}
                  onDelete={handleDelete}
                />
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row className="mt-4 mb-4">
        <Col className="text-center">
          <Button variant="info" onClick={handleDownloadPDF}>Descargar Clasificaciones en PDF</Button>
        </Col>
      </Row>
      <footer className="text-center mt-4">
        <p>Cronostera &copy; 2024</p>
      </footer>
    </Container>
  );
}

export default App;
