import React from 'react';
import { Button } from 'react-bootstrap';

const Corredor = ({ item, formatTime, onEdit, onDelete }) => {
  return (
    <tr>
      <td>{item.position}</td>
      <td>{item.dorsal}</td>
      <td>{item.corredor ? item.corredor.Nombre : ''}</td>
      <td>{item.corredor ? item.corredor.Apellidos : ''}</td>
      <td>{typeof item.time === 'number' ? formatTime(item.time) : item.time}</td>
      <td>
        <Button variant="warning" size="sm" className="me-2" onClick={() => onEdit(item.position)}>Editar</Button>
        <Button variant="danger" size="sm" onClick={() => onDelete(item.position)}>Eliminar</Button>
      </td>
    </tr>
  );
};

export default Corredor;
